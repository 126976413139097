<template>
  <el-container class="home-container">
    <el-aside class="el-aside rr-sidebar hidden-xs-only" style="height: 100%;background-color: #545c64">
        <Header :navActive='navActive'/>
    </el-aside>
    <el-container class="con-right">
      <el-main>
      <Breadcrumb/>

<el-breadcrumb separator-class="el-icon-arrow-right">
  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
  <el-breadcrumb-item>商品管理</el-breadcrumb-item>
  <el-breadcrumb-item>批量下架</el-breadcrumb-item>
</el-breadcrumb>

<!-- 内容 开始 -->
<el-card class="box-card mt20">

    <!-- 搜索区域 -->
    <el-form :inline="true" class="demo-form-inline">
      <div class="demo-input-suffix">
        <el-form-item label="请选择">
          <el-select v-model="queryInfo.userCategorysID" placeholder="请选择" @change="userCategorysClick">
            <el-option
              key="0"
              label="全部"
              value="">
            </el-option>
            <el-option
              v-for="group in userCategorys"
              :key="group.id"
              :label="group.name"
              :value="group.id">
              <el-option
                v-for="item in group.subCategoryList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-option>
          </el-select>
          </el-form-item>
          <el-button type="danger" @click="handleSetup">批量下架</el-button>
      </div>
    </el-form>

    <!-- 商品列表区域 -->
    <div class="main" v-loading="loading">
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选本页</el-checkbox>
      <!-- 数据为空时 -->
      <el-empty description="数据不存在" v-if="dataShow"></el-empty>

      <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange" v-else>
        <el-row :gutter="10">
            <el-col :span="6" v-for="item in tableData" :key="item.productID">
              <el-card :body-style="{ padding: '10px' }">
                <el-image
                style="height: 220px"
                :src="item.image" fit="cover">
                </el-image>
                <div style="padding: 14px;">
                  <span class="text-overflow1">{{item.subject}}</span>
                  <div class="bottom">
                    <el-checkbox :label="item" :key="item">选择</el-checkbox>
                  </div>
                </div>
              </el-card>
            </el-col>
        </el-row>
      </el-checkbox-group>
    </div>

    <!-- 分页区域 -->
    <div class="mt20" v-if="dataShow === false">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, 20]"
        :page-size="queryInfo.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>

</el-card>

      <!-- 内容 结束 -->
    </el-main>
    </el-container>
</el-container>

</template>

<script>
import { defineComponent, ref, reactive, toRefs, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import { getLists } from 'network/productList'
import { listuserCategory } from 'network/usercategory'
// import { taskLists } from 'network/task'
import { shangpinguanliSave } from 'network/shangpinguanli'
import Header from 'components/common/header/Header'
import Breadcrumb from 'components/common/breadcrumb/Breadcrumb'

// import { getLists, deleteByid, deleteAllByid } from 'network/data'
// const dayjs = require('dayjs')

export default defineComponent({
  name: 'Shangpinguanli',
  components: {
    Header,
    Breadcrumb
  },
  setup () {
    const queryInfo = reactive({
      // 搜索关键字
      query: '',
      // 当前状态
      // userCategorys: 'shanghai',
      // 自定定分类
      userCategorysID: '',
      // 当前的页数
      page: 1,
      // 当前每页显示多少条
      page_size: 20
    })

    const options = reactive({
      tableData: []
    })

    // 全选
    const state = reactive({
      dataShow: false,
      checkAll: false,
      checkedCities: [],
      cities: options.tableData,
      isIndeterminate: true,
      ruleTask: '',
      // 总页数
      total: 0,
      userCategorys: [],
      loading: true,
      tabPosition: 'top',
      // 总页数
      tasktotal: 0
    })

    const fullscreenLoading = ref(false)

    const handleCheckAllChange = (val) => {
      state.checkedCities = val ? options.tableData : []
      state.isIndeterminate = false
    }

    const handleCheckedCitiesChange = (value) => {
      const checkedCount = value.length
      state.checkAll = checkedCount === state.cities.length
      state.isIndeterminate = checkedCount > 0 && checkedCount < state.cities.length
    }

    // 批量下架
    const handleSetup = () => {
      console.log(options.tableData)
      shangpinguanliSave(options.tableData).then(res => {
        console.log(res)
        if (res.status === 1) {
          dataInfo(queryInfo)
          ElMessage.success(res.message)
        } else {
          ElMessage.error(res.message)
        }
      })
    }

    // 卖家查询商品列表
    const dataInfo = (queryInfo) => {
      // 复制记录
      state.loading = true
      getLists(queryInfo).then(res => {
        // console.log(res)
        if (res.status === 1) {
          if (res.result.list.length <= 0) {
            state.dataShow = true
          } else {
            state.dataShow = false
            options.tableData = res.result.list
            queryInfo.page = res.result.pageIndex
            queryInfo.page_size = res.result.sizePerPage
            state.total = res.result.totalRecords
          }
          state.loading = false
        } else {
          options.tableData = []
        }
      })
    }

    // 分页
    const handleSizeChange = (val) => {
      // console.log(`每页 ${val} 条`)
      queryInfo.page_size = val
      dataInfo(queryInfo)
    }
    const handleCurrentChange = (val) => {
      // console.log(`当前页: ${val}`)
      queryInfo.page = val
      dataInfo(queryInfo)
    }

    // 点击自定义分类跳转
    const userCategorysClick = (val) => {
      console.log(`当前所属分类: ${val}`)
      queryInfo.userCategorysID = val
      dataInfo(queryInfo)
    }

    onMounted(() => {
      // 获取授权用户的商品自定义分类列表
      listuserCategory().then(res => {
        if (res.status === 1) {
          state.userCategorys = res.result
        } else {
          state.userCategorys = []
        }
      })
      dataInfo(queryInfo)
    })
    return {
      queryInfo,
      ...toRefs(state),
      ...toRefs(options),
      dataInfo,
      handleCheckAllChange,
      handleCheckedCitiesChange,
      handleSetup,
      handleSizeChange,
      handleCurrentChange,
      userCategorysClick,
      fullscreenLoading,
      navActive: '5-1'
    }
  }
})
</script>
<style lang="scss" scoped>
.main{
  margin-top: 20px;
}
.el-select .el-input {
    width: 130px;
  }
.time {
  font-size: 13px;
  color: #999;
}
.bottom {
  margin-top: 13px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  padding: 0;
  min-height: auto;
}

.image {
  width: 100%;
  display: block;
}
.el-col{
  margin-bottom: 20px;
  font-size: 14px;
}
.el-checkbox{
  margin-bottom: 15px;
}
.fm{
  float:right;
}
</style>
